(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/numbergames/product-mapping/assets/javascripts/helpers/handlebarhelpers.js') >= 0) return;  svs.modules.push('/components/numbergames/product-mapping/assets/javascripts/helpers/handlebarhelpers.js');

'use strict';

let productMapping;
if (svs.isServer) {
  const path = require('path');
  productMapping = require(path.join(__dirname, '../', 'product-mapping.es6'));
  registerHelpers(require('hbs'));
} else {
  productMapping = svs.numbergames.productMapping;
  registerHelpers(Handlebars);
}
function registerHelpers(hbs) {
  hbs.registerHelper('brandingClass', productId => productMapping.getBrandingClass(productId));
  hbs.registerHelper('isEurojackpot', (productId, options) => isProductHelper(productMapping.isEurojackpot(productId), options));
  hbs.registerHelper('isKeno', (productId, options) => isProductHelper(productMapping.isKeno(productId), options));
  hbs.registerHelper('isLotto', (productId, options) => isProductHelper(productMapping.isLotto(productId), options));
  hbs.registerHelper('isJoker', (productId, options) => isProductHelper(productMapping.isJoker(productId), options));
  hbs.registerHelper('isVikinglotto', (productId, options) => isProductHelper(productMapping.isVikinglotto(productId), options));
  hbs.registerHelper('isDaySpecificId', (product, options) => isProductHelper(productMapping.isDaySpecificId(product), options));
  hbs.registerHelper('isProductFamily', (product, options) => isProductHelper(productMapping.isProductFamily(product), options));
  hbs.registerHelper('isOneOfProducts', function (productId) {
    for (var _len = arguments.length, params = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      params[_key - 1] = arguments[_key];
    }
    const options = params.pop();
    const isProduct = params.indexOf(productMapping.getProductId(productId)) > -1;
    return isProductHelper(isProduct, options);
  });
  hbs.registerHelper('numbergamesProductMapping', function (functionName) {
    for (var _len2 = arguments.length, params = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      params[_key2 - 1] = arguments[_key2];
    }
    const options = params.pop();
    let result = '';
    const productMappingFunction = productMapping[functionName];
    if (productMappingFunction && typeof productMappingFunction === 'function') {
      result = productMappingFunction(...params);
    }
    if (result && options) {
      if (typeof options.fn === 'function') {
        return options.fn(options.data.root);
      } else if (typeof options.inverse === 'function') {
        return options.inverse(options.data.root);
      }
    }
    return result;
  });
}
function isProductHelper(isProduct, options) {
  if (options) {
    const data = options.data || {};
    if (isProduct) {
      if (typeof options.fn === 'function') {
        return options.fn(data.root);
      }
    } else if (typeof options.inverse === 'function') {
      return options.inverse(data.root);
    }
  }
  return isProduct;
}

 })(window);