(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/numbergames/product-mapping/assets/javascripts/product-mapping.js') >= 0) return;  svs.modules.push('/components/numbergames/product-mapping/assets/javascripts/product-mapping.js');

'use strict';

let urlMapping;
const CONSTANTS = {
  EUROJACKPOT: 'EUROJACKPOT',
  EUROJACKPOT_FRIDAY: 'ELOTTO',
  EUROJACKPOT_TUESDAY: 'TELOTTO',
  KENO: 'KENO',
  LOTTO: 'LOTTO',
  LOTTO_WEDNESDAY: 'ONSLOTTO',
  LOTTO_SATURDAY: 'LORLOTTO',
  VIKINGLOTTO: 'VIKINGLOTTO',
  VIKING: 'VIKING',
  JOKER: 'JOKER',
  JOKER_WEDNESDAY: 'ONSJOKER',
  JOKER_SATURDAY: 'LORJOKER'
};
const joker = {
  name: 'Joker',
  id: 'JOKER',
  allNumericIds: [13, 12],
  drawId: 'joker',
  branding: 'joker',
  isProductFamily: true
};
const onsjoker = {
  name: 'Joker',
  numeric: 13,
  id: 'JOKER',
  itsId: 'ONSJOKER',
  drawId: 'jokerwednesday',
  branding: 'joker',
  day: 'Onsdag',
  dayName: 'Joker Onsdag',
  isDaySpecific: true
};
const lorjoker = {
  name: 'Joker',
  numeric: 12,
  id: 'JOKER',
  itsId: 'LORJOKER',
  drawId: 'jokersaturday',
  branding: 'joker',
  day: 'Lördag',
  dayName: 'Joker Lördag',
  isDaySpecific: true
};
const eurojackpot = {
  name: 'Eurojackpot',
  allNumericIds: [37, 29],
  campaignId: 47,
  campaignItsId: 'ELOTTOACH',
  id: 'EUROJACKPOT',
  wagersId: 'eurojackpot',
  drawId: 'eurojackpot',
  branding: 'eurojackpot',
  isProductFamily: true
};
const eurojackpotFriday = {
  name: 'Eurojackpot',
  numeric: 29,
  campaignId: 47,
  campaignItsId: 'ELOTTOACH',
  id: 'EUROJACKPOT',
  itsId: 'ELOTTO',
  wagersId: 'eurojackpot',
  drawId: 'eurojackpotfriday',
  dayName: 'Eurojackpot Fredag',
  branding: 'eurojackpot',
  day: 'Fredag',
  isDaySpecific: true
};
const eurojackpotTuesday = {
  name: 'Eurojackpot',
  numeric: 37,
  campaignId: 47,
  campaignItsId: 'ELOTTOACH',
  id: 'EUROJACKPOT',
  itsId: 'TELOTTO',
  wagersId: 'eurojackpot',
  drawId: 'eurojackpottuesday',
  branding: 'eurojackpot',
  day: 'Tisdag',
  dayName: 'Eurojackpot Tisdag',
  isDaySpecific: true
};
const keno = {
  name: 'Keno',
  numeric: 9,
  campaignId: 46,
  campaignItsId: 'KENOACH',
  id: 'KENO',
  itsId: 'KENO',
  wagersId: 'keno',
  drawId: 'keno',
  branding: 'keno',
  isDaily: true
};
const lotto = {
  name: 'Lotto',
  id: 'LOTTO',
  allNumericIds: [11, 10],
  campaignId: 35,
  campaignItsId: 'ACHANS',
  wagersId: 'lotto',
  drawId: 'lotto',
  branding: 'lotto',
  joker,
  isProductFamily: true
};
const onslotto = {
  name: 'Lotto',
  numeric: 11,
  campaignId: 35,
  campaignItsId: 'ACHANS',
  id: 'LOTTO',
  itsId: 'ONSLOTTO',
  wagersId: 'lotto',
  drawId: 'lottowednesday',
  branding: 'lotto',
  day: 'Onsdag',
  dayName: 'Lotto Onsdag',
  joker: onsjoker,
  isDaySpecific: true
};
const lorlotto = {
  name: 'Lotto',
  numeric: 10,
  campaignId: 35,
  campaignItsId: 'ACHANS',
  id: 'LOTTO',
  itsId: 'LORLOTTO',
  wagersId: 'lotto',
  drawId: 'lottosaturday',
  branding: 'lotto',
  day: 'Lördag',
  dayName: 'Lotto Lördag',
  joker: lorjoker,
  isDaySpecific: true
};
const vikinglotto = {
  name: 'Vikinglotto',
  numeric: 14,
  id: 'VIKINGLOTTO',
  itsId: 'VIKING',
  wagersId: 'viking',
  drawId: 'vikinglotto',
  branding: 'vikinglotto',
  day: 'Onsdag',
  joker: onsjoker
};
const products = {
  9: keno,
  keno,
  10: lorlotto,
  lor: lorlotto,
  lorlotto,
  'lotto lördag': lorlotto,
  lottolordag: lorlotto,
  lottosaturday: lorlotto,
  11: onslotto,
  ons: onslotto,
  onslotto,
  'lotto onsdag': onslotto,
  lottoonsdag: onslotto,
  lottowednesday: onslotto,
  lotto,
  'lotto onsdag & lördag': lotto,
  'lotto 1': lotto,
  'lotto 1 onsdag': onslotto,
  'lotto 1 lördag': lorlotto,
  'lotto 2': lotto,
  'lotto 2 onsdag': onslotto,
  'lotto 2 lördag': lorlotto,
  12: lorjoker,
  lorjoker,
  'joker lördag': lorjoker,
  jokerlordag: lorjoker,
  jokersaturday: lorjoker,
  13: onsjoker,
  onsjoker,
  'joker onsdag': onsjoker,
  jokeronsdag: onsjoker,
  jokerwednesday: onsjoker,
  joker,
  'joker onsdag & lördag': joker,
  14: vikinglotto,
  viking: vikinglotto,
  vikinglotto,
  29: eurojackpotFriday,
  elotto: eurojackpotFriday,
  eurojackpotfriday: eurojackpotFriday,
  eurojackpotfredag: eurojackpotFriday,
  'eurojackpot fredag': eurojackpotFriday,
  37: eurojackpotTuesday,
  telotto: eurojackpotTuesday,
  eurojackpottuesday: eurojackpotTuesday,
  eurojackpottisdag: eurojackpotTuesday,
  'eurojackpot tisdag': eurojackpotTuesday,
  eurojackpot,
  'eurojackpot tisdag & fredag': eurojackpot
};
function getProduct(product) {
  if (typeof product === 'string') {
    product = product.toLowerCase();
  }
  return products[product];
}
function getProductNumeric(product) {
  product = getProduct(product);
  if (product) {
    return product.numeric;
  }
  return 0;
}
function getProductId(product) {
  product = getProduct(product);
  if (product) {
    return product.id;
  }
  return '';
}
function getProductItsId(product) {
  product = getProduct(product);
  if (product && product.itsId) {
    return product.itsId;
  }
  return '';
}
function getProductWagersId(product) {
  product = getProduct(product);
  if (product) {
    return product.wagersId;
  }
  return '';
}
function getProductName(product) {
  product = getProduct(product);
  if (product) {
    return product.name;
  }
  return '';
}
function getProductDayName(product) {
  product = getProduct(product);
  if (product) {
    return product.dayName || product.name;
  }
  return '';
}
function getProductCampaignId(product) {
  product = getProduct(product);
  if (product) {
    return product.campaignId;
  }
  return '';
}
function getCampaignItsId(product) {
  product = getProduct(product);
  if (product) {
    return product.campaignItsId;
  }
  return '';
}

function getDay(product) {
  product = getProduct(product);
  if (product && product.day) {
    return product.day;
  }
  return '';
}
function getProductDrawId(product) {
  product = getProduct(product);
  if (product) {
    return product.drawId;
  }
  return '';
}
function getBrandingClass(product) {
  product = getProduct(product);
  if (product) {
    return product.branding;
  }
  return '';
}
function getAllNumericIds(product) {
  const productObj = getProduct(product);
  if (productObj) {
    return productObj.allNumericIds || [productObj.numeric];
  }
  if (product !== undefined) {
    return [];
  }
  return [keno.numeric, ...lotto.allNumericIds, ...joker.allNumericIds, vikinglotto.numeric, ...eurojackpot.allNumericIds];
}
function getAllItsIds(product) {
  return getAllNumericIds(product).map(id => getProductItsId(id));
}
function getAllDrawIds(product) {
  return getAllNumericIds(product).map(id => getProductDrawId(id));
}
function hasJoker(product) {
  product = getProduct(product);
  return Boolean(product && product.joker);
}

function getJokerDrawId(product) {
  product = getProduct(product);
  if (product && product.joker) {
    return product.joker.drawId;
  }
  return '';
}

function getJokerProductId(product) {
  product = getProduct(product);
  if (product && product.joker) {
    return product.joker.itsId || product.joker.id;
  }
  return '';
}

function getJokerProductNumeric(product) {
  var _product$joker$numeri, _product;
  product = getProduct(product);
  return (_product$joker$numeri = (_product = product) === null || _product === void 0 || (_product = _product.joker) === null || _product === void 0 ? void 0 : _product.numeric) !== null && _product$joker$numeri !== void 0 ? _product$joker$numeri : 0;
}
function isDaySpecificId(product) {
  var _product$isDaySpecifi, _product2;
  product = getProduct(product);
  return (_product$isDaySpecifi = (_product2 = product) === null || _product2 === void 0 ? void 0 : _product2.isDaySpecific) !== null && _product$isDaySpecifi !== void 0 ? _product$isDaySpecifi : false;
}
function isProductFamily(product) {
  var _product$isProductFam, _product3;
  product = getProduct(product);
  return (_product$isProductFam = (_product3 = product) === null || _product3 === void 0 ? void 0 : _product3.isProductFamily) !== null && _product$isProductFam !== void 0 ? _product$isProductFam : false;
}
function isLotto(product) {
  return getProductId(product) === CONSTANTS.LOTTO;
}
function isLottoSaturday(product) {
  return getProductItsId(product) === CONSTANTS.LOTTO_SATURDAY;
}
function isLottoWednesday(product) {
  return getProductItsId(product) === CONSTANTS.LOTTO_WEDNESDAY;
}
function isJoker(product) {
  return getProductId(product) === CONSTANTS.JOKER;
}
function isJokerWednesday(product) {
  return getProductItsId(product) === CONSTANTS.JOKER_WEDNESDAY;
}
function isJokerSaturday(product) {
  return getProductItsId(product) === CONSTANTS.JOKER_SATURDAY;
}
function isKeno(product) {
  return getProductId(product) === CONSTANTS.KENO;
}
function isEurojackpot(product) {
  return getProductId(product) === CONSTANTS.EUROJACKPOT;
}
function isEurojackpotTuesday(product) {
  return getProductItsId(product) === CONSTANTS.EUROJACKPOT_TUESDAY;
}
function isEurojackpotFriday(product) {
  return getProductItsId(product) === CONSTANTS.EUROJACKPOT_FRIDAY;
}
function isVikinglotto(product) {
  return getProductId(product) === CONSTANTS.VIKINGLOTTO;
}
function isSameProduct(product1, product2) {
  const n1 = getProductNumeric(product1);
  const n2 = getProductNumeric(product2);
  if (n1 && n2) {
    return n1 === n2;
  }
  const p1 = getProductId(product1);
  const p2 = getProductId(product2);
  if (p1 && p2) {
    return p1 === p2;
  }
  return false;
}
function isDaily(product) {
  var _getProduct;
  return Boolean((_getProduct = getProduct(product)) === null || _getProduct === void 0 ? void 0 : _getProduct.isDaily);
}
function getProductUrlMapping(product, urlKey) {
  let params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let url = urlMapping.get("".concat(getBrandingClass(product), "_").concat(urlKey));
  Object.entries(params).forEach(_ref => {
    var _url, _url$replace;
    let [key, value] = _ref;
    url = (_url = url) === null || _url === void 0 || (_url$replace = _url.replace) === null || _url$replace === void 0 ? void 0 : _url$replace.call(_url, ":".concat(key), value);
  });
  return url;
}

function getUrlMappingOngoingBet(product, params) {
  return getProductUrlMapping(product, 'ongoing_bet', params);
}
const productMapping = {
  products,
  CONSTANTS,
  getProductNumeric,
  getProductId,
  getProductCampaignId,
  getCampaignItsId,
  getProductItsId,
  getProductWagersId,
  getProductName,
  getProductDayName,
  getBrandingClass,
  getDay,
  getProductDrawId,
  getAllNumericIds,
  getAllItsIds,
  getAllDrawIds,
  hasJoker,
  getJokerDrawId,
  getJokerProductId,
  getJokerProductNumeric,
  isDaySpecificId,
  isProductFamily,
  isEurojackpot,
  isEurojackpotFriday,
  isEurojackpotTuesday,
  isKeno,
  isLotto,
  isLottoSaturday,
  isLottoWednesday,
  isVikinglotto,
  isJoker,
  isJokerWednesday,
  isJokerSaturday,
  isSameProduct,
  isDaily,
  getUrlMappingOngoingBet
};
if (svs.isServer) {
  const trinidadCore = require('trinidad-core').core;
  urlMapping = trinidadCore.urlMapping;
  module.exports = productMapping;
} else {
  urlMapping = svs.core.urlMapping;
  svs.numbergames = svs.numbergames || {};
  svs.numbergames.productMapping = productMapping;
}

 })(window);